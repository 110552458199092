import {
  add,
  addMinutes,
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  set,
  subMinutes,
} from "date-fns";

const FROM_CAEN = "fromCaen";
const TO_CAEN = "toCaen";

const STOPS = {
  HAUT_LION: "Haut Lion",
  LION_STADE: "Lion Stade",
  SNCF: "Gare SNCF",
  OUISTREAM_PORT: "Ouistream Port",
  TOUR_LEROY: "Tour Leroy",
  CALVAIRE: "Calvaire St-Pierre",
};
const TYPES = {
  BUS12: "Bus 12",
  BUS22: "Bus 22",
  TRAM1: "Tram 1",
  TRAM2: "Tram 2",
  TRAM3: "Tram 3",
  WALK: "Marche",
};

const SNCF_LEROY_WALK_MINUTES = 17;
const MARGIN_TRAM_CHANGE_MINUTES = 5;

const getTwistoTimes = async (line, direction, date, stops) => {
  const res = await fetch(
    `https://twisto.lesbriques.co/timetable/${line}?direction=${direction}&date=${format(
      date,
      "yyyy-MM-dd"
    )}`
  );
  const timetable = await res.json();
  const stopsToKeep = timetable.stops
    .map((stop, index) => ({ stop, index }))
    .filter(({ stop }) => stops.includes(stop.id));
  const trips = timetable.trips
    .map((trip) => {
      const times = stopsToKeep.map(({ index }) => trip.times[index]);
      return {
        ...trip,
        times,
      };
    })
    .filter((trip) => trip.times.some((time) => time !== null));

  return { stops: stopsToKeep.map(({ stop }) => stop), trips };
};

const getBus12TwistoTimes = async (direction, date) => {
  const stops = [
    direction === "RETURN" ? "TWISTO:lmhl02" : "TWISTO:lmhl01",
    direction === "RETURN" ? "TWISTO:poou02" : "TWISTO:poou01",
    direction === "RETURN" ? "TWISTO:tl.1" : "TWISTO:tl.2",
    direction === "RETURN" ? "TWISTO:gasn11" : "TWISTO:gasn12",
  ];
  const bus12 = await getTwistoTimes("12", direction, date, stops);
  const bus12Ex = await getTwistoTimes("12EX", direction, date, stops);

  return {
    stops: bus12.stops,
    trips: [...bus12.trips, ...bus12Ex.trips],
  };
};

const parseTrips = (trips, date) => {
  const dateAt4 = set(date, { hours: 4, minutes: 0, seconds: 0 });
  return trips.map((trip) => {
    const times = trip.times.map((time) => {
      if (time === null) {
        return null;
      }
      const [hours, minutes] = time.split(":").map((t) => parseInt(t, 10));
      return add(dateAt4, { hours: hours - 4 + (hours < 4 ? 24 : 0), minutes });
    });

    return {
      ...trip,
      times,
    };
  });
};

const findSNCFLionTripsWithBus12 = async (direction, date) => {
  const bus12 = parseTrips(
    (
      await getBus12TwistoTimes(
        direction === FROM_CAEN ? "RETURN" : "OUTWARD",
        date
      )
    ).trips,
    date
  );

  const tripsBus12 = bus12
    .map(({ times }) => {
      if (times[0] && times[3]) {
        // The bus goes from Lion to Gare SNCF (or the other way)
        const durationInMinutes = differenceInMinutes(times[3], times[0]);
        return {
          departure: times[0],
          arrival: times[3],
          durationInMinutes,
          legs: [
            {
              departure: times[0],
              arrival: times[3],
              durationInMinutes,
              origin: direction === TO_CAEN ? STOPS.HAUT_LION : STOPS.SNCF,
              destination: direction === TO_CAEN ? STOPS.SNCF : STOPS.HAUT_LION,
              type: TYPES.BUS12,
            },
          ],
        };
      } else if (times[2]) {
        // The bus stops at Tour Leroy but does not go to Gare SNCF
        if (direction === TO_CAEN) {
          if (!times[0]) {
            return null;
          }

          const busDurationInMinutes = differenceInMinutes(times[2], times[0]);
          const arrival = addMinutes(times[2], SNCF_LEROY_WALK_MINUTES);
          return {
            departure: times[0],
            arrival,
            durationInMinutes: busDurationInMinutes + SNCF_LEROY_WALK_MINUTES,
            legs: [
              {
                departure: times[0],
                arrival: times[2],
                durationInMinutes: busDurationInMinutes,
                origin: STOPS.HAUT_LION,
                destination: STOPS.TOUR_LEROY,
                type: TYPES.BUS12,
              },
              {
                departure: times[2],
                arrival,
                durationInMinutes: SNCF_LEROY_WALK_MINUTES,
                origin: STOPS.TOUR_LEROY,
                destination: STOPS.SNCF,
                type: TYPES.WALK,
              },
            ],
          };
        } else if (times[1] && times[3]) {
          const busDurationInMinutes = differenceInMinutes(times[3], times[1]);
          const departure = subMinutes(times[1], SNCF_LEROY_WALK_MINUTES);
          return {
            departure,
            arrival: times[3],
            durationInMinutes: busDurationInMinutes + SNCF_LEROY_WALK_MINUTES,
            legs: [
              {
                departure,
                arrival: times[1],
                durationInMinutes: SNCF_LEROY_WALK_MINUTES,
                origin: STOPS.SNCF,
                destination: STOPS.TOUR_LEROY,
                type: TYPES.WALK,
              },
              {
                departure: times[1],
                arrival: times[3],
                durationInMinutes: busDurationInMinutes,
                origin: STOPS.TOUR_LEROY,
                destination: STOPS.HAUT_LION,
                type: TYPES.BUS12,
              },
            ],
          };
        }
      } else {
        // It can happen the bus only go to Ouistream, in that case
        // we skip it.
        return;
      }
    })
    .filter((t) => !!t);

  return tripsBus12;
};

const findClosestTramGoingToCalvaire = (
  { tram1, tram2 },
  departureFromCalvaire
) => {
  const tram1Before = tram1.filter(({ times }) =>
    isBefore(times[1], departureFromCalvaire)
  );
  const tram2Before = tram2.filter(({ times }) =>
    isBefore(times[1], departureFromCalvaire)
  );
  const bestTram1 = tram1Before[tram1Before.length - 1];
  const bestTram2 = tram2Before[tram2Before.length - 1];
  if (bestTram1) {
    bestTram1.type = TYPES.TRAM1;
  }
  if (bestTram2) {
    bestTram2.type = TYPES.TRAM2;
  }
  if (bestTram1 && bestTram2) {
    return isAfter(bestTram1.times[0], bestTram2.times[0])
      ? bestTram1
      : bestTram2;
  } else if (bestTram1) {
    return bestTram1;
  } else if (bestTram2) {
    return bestTram2;
  }
};

const findClosestTramLeavingCalvaire = (
  { tram1, tram2 },
  arrivalToCalvaire
) => {
  const tram1After = tram1.filter(({ times }) =>
    isAfter(times[0], arrivalToCalvaire)
  );
  const tram2After = tram2.filter(({ times }) =>
    isAfter(times[0], arrivalToCalvaire)
  );
  const bestTram1 = tram1After[0];
  const bestTram2 = tram2After[0];
  if (bestTram1) {
    bestTram1.type = TYPES.TRAM1;
  }
  if (bestTram2) {
    bestTram2.type = TYPES.TRAM2;
  }
  if (bestTram1 && bestTram2) {
    return isBefore(bestTram1.times[0], bestTram2.times[0])
      ? bestTram1
      : bestTram2;
  } else if (bestTram1) {
    return bestTram1;
  } else if (bestTram2) {
    return bestTram2;
  }
};

const findSNCFLionTripsWithBus22 = async (direction, date) => {
  const [bus22Times, tram1Times, tram2Times] = await Promise.all([
    getTwistoTimes("22", direction === FROM_CAEN ? "RETURN" : "OUTWARD", date, [
      direction === FROM_CAEN ? "TWISTO:list02" : "TWISTO:list01",
      "TWISTO:casp41",
      direction === FROM_CAEN ? "TWISTO:tl_4" : "TWISTO:tl.2",
    ]),
    getTwistoTimes("T1", direction === FROM_CAEN ? "RETURN" : "OUTWARD", date, [
      direction === FROM_CAEN ? "TWISTO:gs02" : "TWISTO:gs01",
      direction === FROM_CAEN ? "TWISTO:csp02" : "TWISTO:csp01",
    ]),
    getTwistoTimes("T2", direction === FROM_CAEN ? "RETURN" : "OUTWARD", date, [
      direction === FROM_CAEN ? "TWISTO:rdo02" : "TWISTO:rdo01",
      direction === FROM_CAEN ? "TWISTO:csp02" : "TWISTO:csp01",
    ]),
  ]);

  const bus22 = parseTrips(bus22Times.trips, date);
  const tram1 = parseTrips(tram1Times.trips, date);
  const tram2 = parseTrips(tram2Times.trips, date);

  if (direction === FROM_CAEN) {
    return bus22
      .filter(({ times }) => times[2])
      .map(({ times, onDemand }) => {
        const arrival = times[2];

        // Departure from Tour Leroy
        if (times[0]) {
          const departure = subMinutes(times[0], SNCF_LEROY_WALK_MINUTES);
          const durationInMinutes =
            differenceInMinutes(arrival, departure) + SNCF_LEROY_WALK_MINUTES;
          return {
            departure,
            arrival,
            durationInMinutes,
            legs: [
              {
                departure,
                arrival: times[0],
                durationInMinutes: SNCF_LEROY_WALK_MINUTES,
                origin: STOPS.SNCF,
                destination: STOPS.TOUR_LEROY,
                type: TYPES.WALK,
              },
              {
                departure: times[0],
                arrival,
                durationInMinutes,
                origin: STOPS.TOUR_LEROY,
                destination: STOPS.LION_STADE,
                type: TYPES.BUS22,
              },
            ],
            onDemand,
          };
        } else {
          // Departure from Calvaire
          const bestTram = findClosestTramGoingToCalvaire(
            { tram1, tram2 },
            subMinutes(times[1], MARGIN_TRAM_CHANGE_MINUTES)
          );
          if (bestTram) {
            const departure = bestTram.times[0];
            const durationInMinutes = differenceInMinutes(arrival, departure);

            return {
              departure,
              arrival,
              durationInMinutes,
              legs: [
                {
                  departure,
                  arrival: bestTram.times[1],
                  durationInMinutes: differenceInMinutes(
                    bestTram.times[1],
                    bestTram.times[0]
                  ),
                  origin: STOPS.SNCF,
                  destination: STOPS.CALVAIRE,
                  type: bestTram.type,
                },
                {
                  departure: times[1],
                  arrival,
                  durationInMinutes,
                  origin: STOPS.CALVAIRE,
                  destination: STOPS.LION_STADE,
                  type: TYPES.BUS22,
                },
              ],
              onDemand,
            };
          }
        }
      });
  } else {
    return bus22
      .filter(({ times }) => times[0])
      .map(({ times, onDemand }) => {
        const departure = times[0];
        if (times[2]) {
          // Arrival at Tour Leroy
          const arrival = addMinutes(times[2], SNCF_LEROY_WALK_MINUTES);
          const durationInMinutes = differenceInMinutes(arrival, departure);
          return {
            departure,
            arrival,
            durationInMinutes,
            onDemand,
            legs: [
              {
                departure,
                arrival: times[2],
                durationInMinutes: differenceInMinutes(times[2], times[0]),
                origin: STOPS.LION_STADE,
                destination: STOPS.TOUR_LEROY,
                type: TYPES.BUS22,
              },
              {
                departure: times[2],
                arrival,
                durationInMinutes: SNCF_LEROY_WALK_MINUTES,
                origin: STOPS.TOUR_LEROY,
                destination: STOPS.SNCF,
                type: TYPES.WALK,
              },
            ],
          };
        } else {
          // Arrival at Calvaire
          const bestTram = findClosestTramLeavingCalvaire(
            { tram1, tram2 },
            addMinutes(times[1], MARGIN_TRAM_CHANGE_MINUTES)
          );
          if (bestTram) {
            const arrival = bestTram.times[1];
            const durationInMinutes = differenceInMinutes(arrival, departure);

            return {
              departure,
              arrival,
              durationInMinutes,
              legs: [
                {
                  departure,
                  arrival: times[1],
                  durationInMinutes: differenceInMinutes(times[1], times[0]),
                  origin: STOPS.LION_STADE,
                  destination: STOPS.CALVAIRE,
                  type: TYPES.BUS22,
                },
                {
                  departure: bestTram.times[0],
                  arrival,
                  durationInMinutes: differenceInMinutes(
                    bestTram.times[1],
                    bestTram.times[0]
                  ),
                  origin: STOPS.CALVAIRE,
                  destination: STOPS.SNCF,
                  type: bestTram.type,
                },
              ],
              onDemand,
            };
          }
        }
      });
  }
};

const findSNCFLionTrips = async (direction, date) => {
  const tripsBus12 = await findSNCFLionTripsWithBus12(direction, date);
  const tripsBus22 = await findSNCFLionTripsWithBus22(direction, date);

  const trips = [...tripsBus12, ...tripsBus22]
    .filter((t) => !!t)
    .sort((a, b) => (isBefore(a.departure, b.departure) ? -1 : 1));

  return trips;
};

const findOuistreamLionTrips = async (direction, date) => {
  const bus12 = parseTrips(
    (
      await getBus12TwistoTimes(
        direction === FROM_CAEN ? "OUTWARD" : "RETURN",
        date
      )
    ).trips,
    date
  );

  if (direction === TO_CAEN) {
    return bus12
      .filter((trip) => trip.times[0] && trip.times[1])
      .map((trip) => {
        const departure = trip.times[0];
        const arrival = trip.times[1];
        const durationInMinutes = differenceInMinutes(arrival, departure);

        return {
          departure,
          arrival,
          durationInMinutes,
          legs: [
            {
              departure,
              arrival,
              durationInMinutes,
              origin: STOPS.HAUT_LION,
              destination: STOPS.OUISTREAM,
              type: TYPES.BUS12,
            },
          ],
        };
      });
  }
  if (direction === FROM_CAEN) {
    return bus12
      .filter((trip) => trip.times[2] && trip.times[3])
      .map((trip) => {
        const departure = trip.times[2];
        const arrival = trip.times[3];
        const durationInMinutes = differenceInMinutes(arrival, departure);

        return {
          departure,
          arrival,
          durationInMinutes,
          legs: [
            {
              departure,
              arrival,
              durationInMinutes,
              origin: STOPS.OUISTREAM,
              destination: STOPS.HAUT_LION,
              type: TYPES.BUS12,
            },
          ],
        };
      });
  }
};

const displayTrips = (trips) => {
  console.log(trips);
  let html = "";
  for (const trip of trips) {
    html += `
    <div class="Trip">
      <div class="Trip-Row">
        <div class="Trip-Stop">
          <div class="Trip-Stop-Time">${format(trip.departure, "HH:mm")}</div>
          <div class="Trip-Stop-Place">${trip.legs[0].origin}</div>
        </div>
        <div class="Trip-Transport">
          <div class="Trip-Transport-Icon">→</div>
          <div class="Trip-Transport-Type">${trip.legs[0].type}</div>
        </div>
        ${
          trip.legs.length > 1
            ? `<div class="Trip-Stop Middle">
          <div class="Trip-Stop-Time">${format(
            trip.legs[1].departure,
            "HH:mm"
          )}</div>
          <div class="Trip-Stop-Place">${trip.legs[1].origin}</div>
        </div>
        <div class="Trip-Transport">
          <div class="Trip-Transport-Icon">→</div>
          <div class="Trip-Transport-Type">${trip.legs[1].type}</div>
        </div>`
            : ""
        }
        <div class="Trip-Stop Arrival">
          <div class="Trip-Stop-Time">${format(trip.arrival, "HH:mm")}</div>
          <div class="Trip-Stop-Place">${
            trip.legs[trip.legs.length - 1].destination
          }</div>
        </div>
      </div>
      ${
        trip.onDemand
          ? `<div class="Trip-OnDemand">Ce bus 22 doit être réservé au <a href="tel:+33231155555">02 31 15 55 55</a> avant ${format(
              subMinutes(
                trip.legs.find((leg) => leg.type === TYPES.BUS22).departure,
                120
              ),
              "HH:mm"
            )}.</div>`
          : ""
      }
    </div>
      `;
  }
  document.getElementById("results").innerHTML = html;
};

const findAndDisplayTrips = async (trip, date) => {
  const trips =
    trip === "lion-caen"
      ? await findSNCFLionTrips(TO_CAEN, date)
      : await findSNCFLionTrips(FROM_CAEN, date);

  displayTrips(trips);
};

document.getElementById("date").valueAsDate = new Date();

const onFormChange = () => {
  const trip = document.querySelector("input[name=trip]:checked").value;
  const date = document.getElementById("date").valueAsDate;
  findAndDisplayTrips(trip, date);
};

document.querySelectorAll("input[name=trip]").forEach((input) => {
  input.addEventListener("change", onFormChange);
});
document.getElementById("date").addEventListener("change", onFormChange);

onFormChange();
